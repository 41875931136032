import React from 'react';

const debug = process.env.NODE_ENV !== 'production';
const ErrorPage = props => (
  <div className="content">
    {debug ? (
      <div>
        <div className="text-center">
          <h1>Wystąpił błąd</h1>
        </div>
        <div>
          <p>Szczegóły błędu:</p>
          <p style={{ color: 'red' }}>{props.error.message}</p>
        </div>
      </div>
    ) : (
      <div>
        <div className="text-center">
          <h1>Wystąpił nieoczekiwany problem</h1>
        </div>
        <div>
          <p>
            Jeśli problem nie ustępuje prosimy o kontakt pod adresem:
            <a type="email" href="mailto:info@dyzurnet.pl">
              info@dyzurnet.pl
            </a>
          </p>
        </div>
      </div>
    )}
  </div>
);
export default ErrorPage;
