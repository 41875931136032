import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/shared/ScrollToTop';
import Loading from './components/loading/Loading';
import App from './App';
import 'rc-collapse/assets/index.css';
import './styles/styles.scss';

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <ScrollToTop />
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);
