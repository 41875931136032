import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Slider } from 'react-burgers';
import logo from '../../images/logos/dyzurnet.svg';
import whitelogo from '../../images/logos/dyzurnet-white.svg';
import flag from '../../images/poland-flag.svg';
import classnames from 'classnames';

const plUrl = document.getElementById('url').getAttribute('data-value') || process.env.REACT_APP_PL_URL;

const Navbar = () => {
  let location = useLocation();
  const [openTitle, setOpenTitle] = useState(null); //dropdown
  const [togglerActive, setTogglerActive] = useState(false); //mobile

  useEffect(() => {
    setOpenTitle(null);
  }, [location]);

  const toggleMobileMenu = () => {
    setTogglerActive(!togglerActive);
  };

  return (
    <>
      <div className={classnames('navbar-mobile-bg', { show: togglerActive })} onClick={toggleMobileMenu} />
      <div className={classnames('navbar-container', { 'mobile-active': togglerActive })}>
        <nav className="navbar">
          <Link to="/" className="brand">
            <img
              src={logo}
              alt="Dyżurnet Nawigacja Logo"
              className={classnames('navbar-img navbar-img-desktop', { show: !togglerActive })}
            />
            <img
              src={whitelogo}
              alt="Dyżurnet Nawigacja Logo Białe"
              className={classnames('navbar-img navbar-img-mobile', { show: togglerActive })}
            />
          </Link>
          <Slider
            width={28}
            lineHeight={3}
            color="#c70067"
            active={togglerActive}
            onClick={toggleMobileMenu}
            className="nav-mobile-toggler"
          />
          <div className={classnames('navbar-collapse', { show: togglerActive })}>
            <ul className="navbar-nav">
              <li className="nav-menu-item">
                <NavLink to="/report-illegal-content" exact className="nav-menu-link">
                  Report illegal content
                </NavLink>
              </li>
              <li className="nav-menu-item">
                <NavLink to="/publications" exact className="nav-menu-link">
                  Publications
                </NavLink>
              </li>
              <li className="nav-menu-item">
                <NavLink to="/faq" exact className="nav-menu-link">
                  FAQ
                </NavLink>
              </li>
              <li className="nav-menu-item">
                <NavLink to="/contact" exact className="nav-menu-link">
                  Contact
                </NavLink>
              </li>
              <li className="nav-menu-item">
                <NavLink to="/privacy-policy" exact className="nav-menu-link">
                  Privacy Policy
                </NavLink>
              </li>
              <li className="nav-menu-item">
                <a href={plUrl} className="nav-menu-link">
                  <img src={flag} alt="Dyżurnet polish version" className="navbar-flag" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
