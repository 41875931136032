const routeList = {
  home: '/',
  report: '/report-illegal-content',
  publications: '/publications',
  contact: '/contact',
  faq: '/faq',
  notFound: '/page-not-found',
  privacyPolicy: '/privacy-policy'
};

export default routeList;
