import React from 'react';

const Loading = () => (
  <div className="loading-wrapper">
    <div class="loader">
      <svg
        width="201.13"
        height="52.444"
        data-name="Layer 1"
        version="1.1"
        viewBox="0 0 201.13 52.444"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fill="#212e39"
            d="m135.36 41.45h6.66l5.91 8.1h0.07v-8.1h4v10.88h-6.38l-6-8.27h-0.06v8.27h-4.22zm23.46 0h5.68l6.66 10.88h-5.26l-1.13-2.07h-6.78l-1.13 2.07h-4.71zm4.83 6.66-2.17-4.5h-0.08l-2.23 4.5zm6 0.81h4.71a1.19 1.19 0 0 0 0.57 1 3.47 3.47 0 0 0 1.89 0.5 4.73 4.73 0 0 0 2-0.32c0.5-0.18 0.78-0.45 0.78-0.76a0.72 0.72 0 0 0-0.34-0.59 2.12 2.12 0 0 0-0.84-0.4 7.72 7.72 0 0 0-1.07-0.23c-0.42-0.09-0.78-0.13-1.12-0.18-1.34-0.22-2.46-0.4-3.3-0.63a6.39 6.39 0 0 1-2.1-0.9 2 2 0 0 1-0.85-1.7 2.31 2.31 0 0 1 0.91-1.94 6.15 6.15 0 0 1 2.59-1.12 18 18 0 0 1 3.74-0.36 11.79 11.79 0 0 1 5.05 0.85 2.51 2.51 0 0 1 1.68 2.25v0.32h-4.69a1 1 0 0 0-0.57-1 3.08 3.08 0 0 0-1.68-0.36 2.77 2.77 0 0 0-1.49 0.32c-0.42 0.18-0.63 0.45-0.63 0.72 0 0.54 0.92 0.94 2.82 1.21l2.8 0.41a13.14 13.14 0 0 1 1.89 0.49 4 4 0 0 1 1.55 0.94 2.58 2.58 0 0 1 0.64 1.49 2.49 2.49 0 0 1-1 2 7.06 7.06 0 0 1-2.74 1.16 18.66 18.66 0 0 1-3.72 0.35 20.09 20.09 0 0 1-4.13-0.35 5.26 5.26 0 0 1-2.46-1.21 2.67 2.67 0 0 1-0.86-2m15.41-7.47h4.69v4.77h0.08l5.33-4.77h5.41l-5.76 4.77 6.24 6.15h-5.77l-5.46-5.76h-0.08v5.76h-4.69z"
          />
          <path
            fill="#212e39"
            d="m182.83 19.93v-1.8a4.71 4.71 0 0 0-1-3.25 4.13 4.13 0 0 0-3.16-1h-0.36c-1.62 0-2.74 0.36-3.34 1.08a5.09 5.09 0 0 0-0.72 3.16v1.81a5 5 0 0 0 0.81 3.29 4.29 4.29 0 0 0 3.25 1 5 5 0 0 0 3.43-0.9 4.65 4.65 0 0 0 1-3.38m-9.57 11.39a1 1 0 0 1-0.76-0.23c-0.24-0.18-0.36-0.67-0.36-1.48v-16.37c0-1.05 0.24-1.6 0.72-1.66h0.4a1.11 1.11 0 0 1 1 0.36c0.15 0.18 0.26 0.27 0.32 0.27l1.49-0.4a13.15 13.15 0 0 1 3.15-0.27 8 8 0 0 1 3.07 0.63 4.6 4.6 0 0 1 2.52 2.29 6.31 6.31 0 0 1 0.59 2.84v3.7a4.93 4.93 0 0 1-1.85 4 7.17 7.17 0 0 1-4.64 1.49h-1.1a9.63 9.63 0 0 1-2.66-0.32 0.12 0.12 0 0 0-0.09 0q-0.18 0-0.45 0.36a1.92 1.92 0 0 0-0.18 1v2.09c0 1-0.24 1.57-0.72 1.66a1.7 1.7 0 0 1-0.41 0"
          />
          <path
            fill="#212e39"
            d="m10.984 21.87v-5.82a2.19 2.19 0 0 0-1-1.94 4.11 4.11 0 0 0-2.16-0.5q-4.16 0-5.05 2.58a7 7 0 0 0-0.27 2v0.55a18.33 18.33 0 0 0 0.09 1.85c0.29 2.51 1.83 3.74 4.6 3.74 1.74 0 2.9-0.45 3.47-1.35a1.93 1.93 0 0 0 0.32-1.13m1.13 4.6a1.47 1.47 0 0 1-1.13-0.59l-1.4 0.45a6.89 6.89 0 0 1-1.44 0.14h-1.42l-1.31-0.09c-2.58-0.18-4.21-1.35-4.87-3.52a13.8 13.8 0 0 1-0.54-4.19q0-4.16 2.25-5.91a7.55 7.55 0 0 1 4.6-1.17c2.14 0 3.39 0.13 3.75 0.4a0.41 0.41 0 0 0 0.18 0.05q0.18 0 0.18-0.27v-4.67c0-1.06 0.22-1.6 0.68-1.63a2.28 2.28 0 0 1 0.45 0 1.14 1.14 0 0 1 0.81 0.22 2.21 2.21 0 0 1 0.31 1.45v17.63c0 1-0.22 1.58-0.67 1.67a2.3 2.3 0 0 1-0.45 0"
          />
          <path
            fill="#212e39"
            d="m23.514 19.88c0.33 0 0.77-0.55 1.31-1.67l2.48-5.54q0.49-1 1.17-1.08a0.39 0.39 0 0 1 0.14 0 0.82 0.82 0 0 1 0.63 0.32 1.12 1.12 0 0 1 0.22 0.67 2.24 2.24 0 0 1-0.27 1l-4.64 10.18-1.83 4.24c-0.75 1.59-1.43 2.54-2 2.84a4.81 4.81 0 0 1-2 0.5 2.48 2.48 0 0 1-0.68-0.09 1.5 1.5 0 0 1-0.36-0.09q-0.13 0-0.18-0.27a0.41 0.41 0 0 1 0-0.18 1.3 1.3 0 0 1 0.41-0.81 2 2 0 0 1 0.81-0.57 5.93 5.93 0 0 0 2.34-2.25 5.33 5.33 0 0 0 0.86-1.9 3.19 3.19 0 0 0 0-0.54 7.85 7.85 0 0 0-0.58-2.75c-0.33-0.81-0.56-1.33-0.68-1.58a7.44 7.44 0 0 1-0.31-0.72l-2.88-6.16a2.24 2.24 0 0 1-0.32-1 1 1 0 0 1 0.23-0.68 0.77 0.77 0 0 1 0.72-0.41c0.54 0 1 0.35 1.35 1l2.75 5.86c0.48 1.12 0.92 1.67 1.31 1.67"
          />
          <path
            fill="#212e39"
            d="m46.154 12.71a2.6 2.6 0 0 1-0.58 1.9l-8.26 8.48c-0.24 0.24-0.36 0.43-0.36 0.58s0.12 0.33 0.36 0.45a1.45 1.45 0 0 0 0.54 0.09h6.59c0.84 0 1.37 0.18 1.58 0.54a1.15 1.15 0 0 1 0.13 0.59 1 1 0 0 1-1.08 1.13h-9.88c-1.14 0-1.71-0.38-1.71-1.13v-0.31a2.09 2.09 0 0 1 0.59-1.58l8-8.48a1 1 0 0 0 0.22-0.59 0.61 0.61 0 0 0-0.27-0.45 0.94 0.94 0 0 0-0.49-0.09h-6.38a1.85 1.85 0 0 1-1.54-0.63 0.82 0.82 0 0 1-0.13-0.5 1 1 0 0 1 0.59-0.9 2.23 2.23 0 0 1 1.08-0.22h9.29a2 2 0 0 1 1.53 0.54 0.89 0.89 0 0 1 0.18 0.58"
          />
          <path
            fill="#212e39"
            d="m50.114 13.25c0-1.05 0.24-1.6 0.72-1.66h0.41a1.15 1.15 0 0 1 0.77 0.18 2.16 2.16 0 0 1 0.36 1.48v6.9a3.67 3.67 0 0 0 2.35 3.7 5 5 0 0 0 2 0.36 4.08 4.08 0 0 0 2.62-0.67 3.59 3.59 0 0 0 1.13-2.89v-7.4c0-1.05 0.23-1.6 0.68-1.66h0.45a1 1 0 0 1 0.72 0.18 2.08 2.08 0 0 1 0.41 1.48v11.5c0 1.09-0.24 1.66-0.72 1.72h-0.41a1.86 1.86 0 0 1-0.81-0.23 1.83 1.83 0 0 0-0.81-0.22 9.25 9.25 0 0 0-1.35 0.22 9.37 9.37 0 0 1-1.85 0.23c-3.24 0-5.3-1.33-6.18-4a8.72 8.72 0 0 1-0.45-2.93z"
          />
          <path
            fill="#212e39"
            d="m68.554 24.21c0.6 0 0.9-0.31 0.9-0.94v-8.48a0.88 0.88 0 0 0-0.9-1 1.65 1.65 0 0 1-1.58-0.72 0.91 0.91 0 0 1-0.09-0.41 1.12 1.12 0 0 1 0.18-0.76 2.19 2.19 0 0 1 1.49-0.36 13.46 13.46 0 0 1 2.39 0.27 3.65 3.65 0 0 0 0.59 0c0.09 0 0.7-0.1 1.85-0.31a16.67 16.67 0 0 1 2.57-0.14 4 4 0 0 1 2.52 0.86 2.38 2.38 0 0 1 1.13 1.89v0.77a0.41 0.41 0 0 1 0.05 0.18 1.87 1.87 0 0 1-0.41 1.22 0.86 0.86 0 0 1-0.68 0.31q-0.72 0-1.35-1.08a4.6 4.6 0 0 0-1.31-1.58 2.53 2.53 0 0 0-1.3-0.41 2.89 2.89 0 0 0-2.58 1.22 1.83 1.83 0 0 0-0.31 1.08v7.36c0 0.39 0.1 0.64 0.31 0.76a7 7 0 0 0 2.08 0.18q1.62 0 1.71 0.72v0.49a1.15 1.15 0 0 1-0.18 0.77 2.26 2.26 0 0 1-1.53 0.36h-5.55c-1 0-1.61-0.24-1.67-0.72v-0.41a1.15 1.15 0 0 1 0.18-0.77 2.19 2.19 0 0 1 1.49-0.36"
          />
          <path
            fill="#212e39"
            d="m85.914 12.26q0 0.09 0.18 0.09a4 4 0 0 0 1-0.22 12.84 12.84 0 0 1 3.7-0.54 7.44 7.44 0 0 1 4.33 1.21 4.09 4.09 0 0 1 1.84 3.53v8.38c0 1.09-0.23 1.66-0.68 1.72h-0.45a1 1 0 0 1-0.72-0.18 2.19 2.19 0 0 1-0.41-1.54v-7.22a3.33 3.33 0 0 0-2.57-3.38 6.25 6.25 0 0 0-1.89-0.27q-3.12 0-4.06 2.53a3.7 3.7 0 0 0-0.27 1.39v7c0 1.09-0.24 1.66-0.72 1.72h-0.41a1.15 1.15 0 0 1-0.77-0.18 2.28 2.28 0 0 1-0.36-1.54v-11.51c0-1.05 0.24-1.6 0.72-1.66h0.41c0.63 0 1 0.22 1.13 0.67"
          />
          <path
            fill="#212e39"
            d="m104.26 17h5c1.05 0 1.58-0.24 1.58-0.72a2.17 2.17 0 0 0-0.73-1.54 4.83 4.83 0 0 0-3.38-1 4 4 0 0 0-3.25 1.21 1.26 1.26 0 0 0-0.31 0.86q0 1 0.54 1.17a3.09 3.09 0 0 0 0.59 0.05m-3.84 0.81c0-2.26 0.54-3.86 1.63-4.83a7 7 0 0 1 4.73-1.39 8 8 0 0 1 5 1.66 5.1 5.1 0 0 1 1.44 1.76 4.29 4.29 0 0 1 0.41 1.76v0.32a2.13 2.13 0 0 1-0.68 1.67 4.18 4.18 0 0 1-2.16 0.36h-7.11c-0.7 0-1 0.55-1 1.67a3.24 3.24 0 0 0 1.35 2.61 5.2 5.2 0 0 0 3.2 1 5.62 5.62 0 0 0 2.93-1 3.68 3.68 0 0 0 0.73-0.63 2.57 2.57 0 0 1 1.53-1h0.09a0.68 0.68 0 0 1 0.41 0.14 0.66 0.66 0 0 1 0.31 0.63 2 2 0 0 1-0.09 0.54 2.46 2.46 0 0 1-0.63 1.13l-1 0.86a6.65 6.65 0 0 1-4.56 1.44 7.77 7.77 0 0 1-5.18-1.63 3.48 3.48 0 0 1-1.36-2.88z"
          />
          <path
            fill="#212e39"
            d="m128.56 23.04a1 1 0 0 1 1.08 0.68 1.77 1.77 0 0 1-0.54 1.21 6.18 6.18 0 0 1-3.25 1.45 10.28 10.28 0 0 1-1.44 0.09q-3.44 0-4.6-2.62a6.07 6.07 0 0 1-0.41-2.39v-6.85a0.78 0.78 0 0 0-0.31-0.68 0.32 0.32 0 0 0-0.23-0.09c-1 0-1.61-0.21-1.67-0.63v-0.5a1.12 1.12 0 0 1 0.18-0.76 2.19 2.19 0 0 1 1.49-0.36c0.24 0 0.42-0.18 0.54-0.55a0.86 0.86 0 0 0 0-0.27v-3.69c0-1.06 0.26-1.6 0.77-1.63a1.47 1.47 0 0 1 0.36 0 1.14 1.14 0 0 1 0.81 0.22 2.2 2.2 0 0 1 0.32 1.45v3.83a0.67 0.67 0 0 0 0.18 0.52 1 1 0 0 0 0.63 0.16h3.43c1 0 1.58 0.24 1.67 0.72a1.64 1.64 0 0 1 0 0.4 1 1 0 0 1-0.23 0.77 2.14 2.14 0 0 1-1.48 0.36h-3.43a0.94 0.94 0 0 0-0.63 0.16 0.79 0.79 0 0 0-0.18 0.61v6.85a2.62 2.62 0 0 0 0.85 2.12 3.76 3.76 0 0 0 1.9 0.63 2.46 2.46 0 0 0 0.49 0 4.39 4.39 0 0 0 2.44-0.77 4 4 0 0 1 1.22-0.45"
          />
          <path
            fill="#212e39"
            d="m40.814 6.83a1.44 1.44 0 0 1 1.06 0.45 1.43 1.43 0 0 1 0.45 1.05 1.51 1.51 0 0 1-2.6 1.08 1.5 1.5 0 0 1-0.42-1.08 1.52 1.52 0 0 1 1.51-1.51"
          />
          <path
            fill="#212e39"
            d="m191.16 26.43c-1 0-1.58-0.24-1.67-0.72a1.73 1.73 0 0 1 0-0.41 1 1 0 0 1 0.27-0.81 2.31 2.31 0 0 1 1.44-0.31h2.12c0.54 0 0.86-0.06 0.95-0.19a1.51 1.51 0 0 0 0.18-0.94v-14.3a1.1 1.1 0 0 0-0.72-1 0.68 0.68 0 0 0-0.41-0.14c-1.35 0-2.06-0.22-2.12-0.67v-0.45c0-0.75 0.56-1.13 1.67-1.13h2.17a1.62 1.62 0 0 1 1.48 0.63 2.4 2.4 0 0 1 0.18 1v16a1.51 1.51 0 0 0 0.18 0.94c0.09 0.13 0.41 0.19 0.95 0.19h1.63c1.05 0 1.6 0.24 1.66 0.72v0.49a1.22 1.22 0 0 1-0.18 0.81 2.51 2.51 0 0 1-1.48 0.32z"
          />
        </g>
        <g className="loader-animated-dot">
          <path fill="#c70067" d="m165.84 15.24a15.24 15.24 0 1 0-15.24 15.24 15.24 15.24 0 0 0 15.24-15.24" />
          <polygon
            fill="#fff"
            transform="translate(-33.276,-21.67)"
            points="183.87 32.99 189.76 27.11 193.68 31.03 187.8 36.91 193.69 42.8 189.76 46.72 183.87 40.83 177.99 46.72 174.07 42.8 179.96 36.91 174.07 31.03 177.99 27.11"
          />
        </g>
      </svg>
    </div>
  </div>
);

export default Loading;
