import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ErrorBoundary from './components/error/ErrorBoundary';
import Navbar from '../src/components/layout/Navbar';
import routeList from './routes/routeList';
import routes from './routes/index';
const App = () => {
  return (
    <ErrorBoundary>
      <Navbar />
      <Switch>
        {routes.map((route, index) => (
          <Route key={index} exact path={route.path}>
            {route.component}
          </Route>
        ))}
        <Redirect to={routeList.notFound} exact />
      </Switch>
    </ErrorBoundary>
  );
};
export default App;
