import React from 'react';
import AsyncLoader from '../components/loading/AsyncLoader';
import routeList from './routeList';

const FormLoader = AsyncLoader(() => import('../components/pages/ReportForm'));
const AboutLoader = AsyncLoader(() => import('../components/pages/About'));
const PublicationsLoader = AsyncLoader(() => import('../components/pages/Publications'));
const ContactLoader = AsyncLoader(() => import('../components/pages/Contact'));
const FAQLoader = AsyncLoader(() => import('../components/pages/FAQ'));
const NotFoundLoader = AsyncLoader(() => import('../components/pages/NotFound'));
const PrivacyPolicyLoader = AsyncLoader(() => import('../components/pages/PrivacyPolicy'));

const routes = [
  {
    path: routeList.home,
    component: <AboutLoader />
  },
  {
    path: routeList.privacyPolicy,
    component: <PrivacyPolicyLoader />
  },
  {
    path: routeList.report,
    component: <FormLoader />
  },
  {
    path: routeList.publications,
    component: <PublicationsLoader />
  },
  {
    path: routeList.contact,
    component: <ContactLoader />
  },
  {
    path: routeList.faq,
    component: <FAQLoader />
  },
  {
    path: routeList.notFound,
    component: <NotFoundLoader />
  }
];

export default routes;
